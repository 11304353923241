import translation from '../../translation/translate';

const defaultMessage = {
    required: () => 'Ce champs est obligatoire',
    number: () => "Ce champs n'accepte que les chiffres",

};

export function errorFormDefaultMessage(errors, types, errorMessages) {
    let defaultError = errorMessages + ' invalide';
    if (typeof errors === 'object') {
        for (let key of Object.keys(errors)) {
            if(types === "password"){
               return defaultError = translation[process.env.REACT_APP_CLIENT].register_wording_password;
            }
            else{
                if (defaultMessage[key]) {
                    return defaultMessage[key](errors[key]);
                }
            }
        }
    }
    return defaultError;
}

export function errorFormMessage(errors, errorMessages, types) {
    if (typeof errors === 'object' && typeof errorMessages === 'object') {
        for (let key of Object.keys(errors)) {
            if (errorMessages[key]) {
                return errorMessages[key](errors[key]);
            }
        }
    }
    return errorFormDefaultMessage(errors, types, errorMessages);
}

