import '@babel/polyfill'
import 'url-search-params-polyfill'
import React from 'react'
import ReactDOM from 'react-dom'
import { Router, Route, Switch } from 'react-router-dom'
import { Provider } from 'react-redux'
import indexRoutes from './routes'
import { ApolloProvider } from 'react-apollo'
import history from './history'
import configureStore from './configureStore'
import client from './configureApolloClient'
import 'react-toastify/dist/ReactToastify.css';
import './assets/css/material-dashboard-pro-react.css?v=1.2.0'
import {ToastContainer} from "react-toastify";
import './assets/css/body-style.css';
const { store } = configureStore();

ReactDOM.render(
  <ApolloProvider client={client}>
    <ToastContainer/>
    <Provider store={store}>
      <Router history={history}>
          <Switch>
            {/*  {actualDate >= startParticipation &&
              <div>
                  <Redirect
                      to={{
                          pathname: '/fermeture',

                      }}
                  />
                  <OuverturePage/>
              </div>
              }*/}

              {indexRoutes.map((prop, key) => <Route path={prop.path} component={prop.component} key={key} />)}


          </Switch>
      </Router>
    </Provider>
  </ApolloProvider>,

  document.getElementById("root")
);
