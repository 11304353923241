import axios from 'axios'
import { getUserToken } from '../../shared/utils/utils'

const userApi = {
  update: (data: any) => (
    axios.put(`${getUserToken().type}/${getUserToken().id}/profile`, data)
  ),
  change_pwd: (data: any) => (
    axios.put(`${getUserToken().type}/${getUserToken().id}/change-pwd`, data)
  ),
  change_iban: (data: any) => (
    axios.put(`${getUserToken().type}/${getUserToken().id}/change-iban`, data)
  ),
  get_curr: () => (
    axios.get(`${getUserToken().type}/${getUserToken().id}`)
  )
};

export default userApi
