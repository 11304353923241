import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress'
import api from '../../api'
import pricingPageStyle from '../../assets/css/pricingPageStyle.jsx'
import Typography from "@material-ui/core/Typography";
import OperationCardItem from "./operation-card-item.component";
import style from '../../assets/css/style';

interface Props {
    classes: any;
}

interface State {
    operations: any;
    progress: boolean;
}

class OperationsPage extends React.Component <Props, State> {
    state = {
        operations: [],
        progress: false
    };

    componentDidMount() {
        this.setState({progress: true});
        api.operations.my_operations()
            .then(resp => this.setState({operations: resp.data['hydra:member'], progress: false}))
            .catch(() => this.setState({progress: false}))
    }

    render() {
        const {classes} = this.props;
        const {operations, progress} = this.state;

        return (
            <div style={{position: 'relative', minHeight: 'calc(100vh - 82px)', paddingTop: '85px'}}>
                <div className={classes.container}>
                    <Grid container justifyContent='center'>
                        {progress && <CircularProgress style={style.title as any} color="secondary"/>}
                        {!progress && <Grid item xs={12} sm={12} md={6}>
                        </Grid>}
                    </Grid>
                    <Grid container justifyContent='center'>
                        {0 === operations.length && !progress && <Typography gutterBottom color="error">
                            Aucune opération disponible pour le moment.
                        </Typography>}
                        {operations.map((ope: any, key: number) => (
                            <Grid item key={key} xs={12} sm={6} md={4}>
                                <OperationCardItem operation={ope}/>
                            </Grid>
                        ))}
                    </Grid>
                </div>
            </div>
        );
    }
}

export default withStyles(pricingPageStyle as any)(OperationsPage);
