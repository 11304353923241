import OperationsPage from '../operations/list/operation-page.component'
import LoginPage from '../authentication/components/login/login.component'
import ResetPwdPage from '../authentication/components/password/reset-password-page.component'
import ConsumerRequestPage from '../participations/list/consumer-request-page'
import ConsumerNewRequestPage from '../participations/create/create-participation-page.component'
import ProfilePage from '../profil/components/profil.component'
import RecapParticipation from '../participations/create/summary-participation.component'
import translation from '../translation/translate'

const pagesRoutes = [
  {
    path: '/',
    name: 'Home',
    requireAuth: true,
    component: OperationsPage
  },
  {
    path: '/nouvelle-demande/:ope_id',
    name: 'Nouvelle demande',
    requireAuth: true,
    noHeader: true,
    component: ConsumerNewRequestPage
  },
  {
    path: '/recap-participation/:ope_id',
    name: 'Recap participation',
    requireAuth: true,
    noHeader: true,
    component: RecapParticipation
  },
  {
    path: '/modifier-demande/:ope_id/part/:part_id',
    name: 'Modifier demande',
    requireAuth: true,
    noHeader: true,
    component: ConsumerNewRequestPage
  },
  {
    path: '/mes-demandes',
    name: translation[process.env.REACT_APP_CLIENT as any].navbar_demandes,
    requireAuth: true,
    component: ConsumerRequestPage
  },
  {
    path: '/login',
    name: 'Connexion',
    noHeader: true,
    requireAuth: false,
    component: LoginPage
  },
  {
    path: '/reiniialiser-mot-de-passe/:token',
    name: 'Reset PWD',
    noHeader: true,
    component: ResetPwdPage
  },
  {
    path: '/mon-profil',
    name: 'profil',
    requireAuth: true,
    component: ProfilePage
  },
  {
    redirect: true,
    path: '/',
    pathTo: '/',
    name: 'Page non trouvée'
  }
];

export default pagesRoutes;
