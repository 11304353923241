import {container, defaultFont} from "./material-dashboard-pro-react.jsx";

const color = require(`./${process.env.REACT_APP_CLIENT}/style.jsx`);
const background = require(`../img/${process.env.REACT_APP_CLIENT}/background.png`);

const firstColor = color.firstColor;
const secondColor = color.secondColor;
//const thirdColor = color.thirdColor;
const fourthColor = color.fourthColor;

const style = {
    container: {
        ...container
    },
    content: {
        minHeight: "calc(100vh - 84px)",
        position: "relative",
        zIndex: "4",
    },
    containerCustom:{
        position: 'relative',
        minHeight: 'calc(100vh - 82px)',
        paddingTop: '85px'
    },
    btnLogin: {
        borderRadius: '3px',
        color: 'white',
        backgroundColor: firstColor,
        cursor: 'pointer',
        paddingLeft: '41px',
        paddingRight: '41px',
        margin: 'auto',
        marginTop: '3%',
        display: 'block',
    },
    iconProfil: {
        color: secondColor
    },
    btnCancel: {
        borderRadius: '3px',
        border: '1px solid',
        backgroundColor: '#fff',
        color: firstColor,
        marginTop: '28px',
    },
    btnSend: {
        borderRadius: '3px',
        border: '1px solid',
        backgroundColor: firstColor,
        color: 'white',
        marginTop: '28px',
    },
    subTitleProfil: {
        textAlign: 'center',
        color: secondColor
    },
    btnPswForget: {
        color: firstColor,
        fontSize: '14px',
        border: 'none',
        margin: 'auto',
        display: 'block'
    },
    titleOP: {
        color: secondColor,
        textTransform: 'uppercase',
        textAlign: 'center',
        marginTop: '0',
        fontWeight: '700',
        fontSize: '20px',
    },
    cardCategory: {
        color: "#999999",
        marginTop: "10px"
    },
    footer: {
        backgroundColor: '#f8f8f8',
        left: '0px',
        right: '0px',
        bottom: '0px',
        zIndex: '4',
        padding: "6px 0px",
        marginTop: '15px'
    },
    list: {
        marginBottom: "0",
        padding: "0",
        paddingLeft: '12%',
        marginTop: "0"
    },
    inlineBlock: {
        display: "inline-block",
        marginRight:'8%',
        color:firstColor,
        padding: "0",
        width: "auto"
    },
    fab: {
        backgroundColor: '#0093d0',
    },
    chip: {
        marginTop: '10px',
        marginBottom: '10px',
    },
    btnValidatePart: {
        border: '1px solid ba8e84',
        float: 'right',
        color: 'white',
        fontSize: '13px',
        fontWeight: 'bold',
        backgroundColor: firstColor,
        maxWidth: '212px',
        wordWrap: 'break-word',
        textTransform: 'none',
        whiteSpace: 'normal',
        marginTop: '10px',
    },
    btnModified: {
        border: '1px solid #F9A08D',
        float: 'right',
        color: '#F9A08D',
        fontSize: '13px',
        fontWeight: 'bold',
        backgroundColor: '#ffffff',
        maxWidth: '212px',
        marginRight: '12px',
        wordWrap: 'break-word',
        textTransform: 'none',
        whiteSpace: 'normal',
        marginTop: '10px',
    },
    cardTitle: {
        textAlign: "center",
        color: fourthColor,
    },
    center: {
        textAlign: "center"
    },
    title: {
        color: firstColor,
        textAlign: 'center',
        fontWeight: 'bold',
    },
    titleBis: {
        color: firstColor,
        marginTop: '45px',
        textAlign: 'center',
        fontWeight: 'bold',
        marginBottom: '20px'
    },
    spanColor: {
        color: fourthColor,
        marginLeft: '3px',
        marginRight: '3px',
    },
    btnEye: {
        color: '#f49c00',
        cursor: 'pointer'
    },
    btnPart: {
        background: firstColor,
        padding: '15px',
        color: 'white',
        fontSize: '13px',
        fontWeight: 'bold',
        paddingLeft: '25px',
        paddingRight: '25px',
        minWidth: '212px',
        whiteSpace: 'normal',
        textTransform: 'none',
        '&:hover': {
            background: 'linear-gradient(45deg, #00965f 50%, #F9A08D 60%)',
            color: '#ffffff',
        }
    },
    btnPartFinish: {
        background: firstColor,
        padding: '15px',
        color: 'white',
        cursor: 'not-allowed',
        opacity: '0.5',
        fontSize: '13px',
        fontWeight: 'bold',
        paddingLeft: '25px',
        paddingRight: '25px',
        minWidth: '212px',
        whiteSpace: 'normal',
        textTransform: 'none',
        '&:hover': {
            background: 'linear-gradient(45deg, #00965f 50%, #F9A08D 60%)',
            color: '#ffffff',
        }
    },
    conditionGeneralNone: {
        border: '1px solid first',
        color: 'white',
        cursor: 'not-allowed',
        fontSize: '13px',
        fontWeight: 'bold',
        opacity: '0.5',
        backgroundColor: secondColor,
        maxWidth: '212px',
        wordWrap: 'break-word',
        textTransform: 'none',
        whiteSpace: 'normal',
        marginTop: '10px',
        padding: '15px 25px',
        minWidth: '212px'
    },
    conditionGeneral: {
        border: '1px solid first',
        color: 'white',
        fontSize: '13px',
        fontWeight: 'bold',
        backgroundColor: secondColor,
        maxWidth: '212px',
        wordWrap: 'break-word',
        textTransform: 'none',
        whiteSpace: 'normal',
        marginTop: '10px',
        padding: '15px 25px',
        minWidth: '212px'
    },
    body: {
        color: '#3C4858',
        fontSize: '14px',
        backgroundColor: 'blue!important'
    },
    modalListWrapper: {
        fontWeight: '500',
        color: '#000',
        paddingLeft: '20px',
        marginBottom: '0',
    },
    modalTitle: {
        fontWeight: '900',
        marginBottom: '0',
        marginTop: '0',
        textTransform: 'uppercase',
        textAlign: 'left',
        color: '#000',
        fontSize: '17px',
    },
    modalText: {
        fontWeight: '500',
        color: '#000',
    },
    modalListItemBold: {
        fontWeight: '900',
    },
    titleWhite: {
        color: 'white',
    },
    criteresUpload: {
        color: '#636466',
        marginRight: '10px',
    },
    alignRight: {
        textAlign: 'right',
    },
    customImageOperation: {
        width: '100%',
        marginTop: '-40px',
        boxShadow: '0px 0px 20px 0px rgba(0,0,0,0.2)',
        borderRadius: '3px',
    },
    customTableImage: {
        width: '100%',
    },
    opTitle: {
        textAlign: 'center',
        color: secondColor,
    },
    labelOperationGeneric: {
        textAlign: 'center',
        color: 'white',
        backgroundColor: secondColor,
        borderRadius: '2px',
        marginTop: '0px',
        fontSize: '15px',
        padding: '8px'

    },
    spanOperationGeneric: {
        color: firstColor,
        textAlign: 'center',
        marginLeft: '10px',
        paddingBottom: '8px',
        fontSize: '12px'
    },
    paperUpload: {
        border: '2px solid',
        color: secondColor,
    },
    titleRecap: {
        color: firstColor
    },
    email_aide: {
        color: firstColor
    },
    btnUpload: {
        margin: '8px',
        color: firstColor,
        fontSize: '14px',
        boxShadow: 'none',
        fontWeight: '700',
        textTransform: 'none',
        backgroundColor: 'transparent',
    },
    divUpload: {
        color: 'white',
        backgroundColor: secondColor,
        borderRadius: '50px',
        padding: '12px',
        margin: 'auto',
        display: 'block',
    },
    btnOpacity: {
        opacity: '0.4',
        backgroundColor: secondColor,
        padding: '10px',
        color: 'white',
        fontSize: '13px',
        fontWeight: 'bold',
        whiteSpace: 'normal'
    },
    notBtnOpacity: {
        backgroundColor: secondColor,
        padding: '10px',
        color: 'white',
        fontSize: '13px',
        fontWeight: 'bold',
        whiteSpace: 'normal'
    },
    backgroundImage: {
        width: '100%',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        backgroundImage: `url(${background})`
    },
    sidebarButton: {
        "&,&:hover,&:focus": {
            color: firstColor,
        },
        top: "-2px"
    },
    appBar: {
        backgroundColor: "#FFFFFF",
        boxShadow: "none",
        borderBottom: "0",
        marginBottom: "0",
        position: "absolute",
        width: "100%",
        paddingTop: "10px",
        zIndex: "1029",
        color: "#7e7573",
        border: "0",
        borderRadius: "3px",
        padding: "10px 0",
        transition: "all 150ms ease 0s",
        minHeight: "50px",
        display: "block",
    },
}

export default style;

