import React from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import PagesHeader from "./PagesHeader"
import Footer from "./Footer"
import AuthRouter from "../authentication/actions/auth-router.action";
import pagesRoutes from "../routes/pages"
import { loginSuccess } from '../authentication/actions/auth-function.action'
import { connect } from "react-redux"
import api from '../api/index'

class Pages extends React.Component {
  componentDidMount() {
    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const token = params.get('token');
    if(token){
      sessionStorage.user_jwt = token
      api.setAuthorizationHeader(token)
      this.props.loginSuccess(token)
        this.props.history.push('/')
    }
  }
  render() {
    const { classes, ...rest } = this.props;

    return (
        <div >
          <PagesHeader {...rest} />
          <div  style={{backgroundSize:'cover', backgroundPosition:'center top', top:'0px', left:'0px', width:'100%', height:'100%', zIndex:'1', display:'block'}} ref="wrapper">
              <Switch>
                {pagesRoutes.map((prop, key) => {
                  if (prop.redirect) {
                    return (
                        <Redirect from={prop.path} to={prop.pathTo} key={key} />
                    );
                  }
                  if (prop.requireAuth) {
                    return <AuthRouter
                        exact
                        path={prop.path}
                        component={prop.component}
                        key={key}
                    />
                  }

                  return (
                      <Route
                          exact
                          path={prop.path}
                          component={prop.component}
                          key={key}
                      />
                  );

                })}
              </Switch>
              <Footer />

          </div>
        </div>
    );
  }
}

export default connect(null, { loginSuccess })(Pages);
