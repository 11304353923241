import React from 'react'
import PropTypes from 'prop-types'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid';
import {connect} from 'react-redux'
import {login} from '../../actions/auth-function.action'
import LoginForm from './login-form.component';
import {Tabs, Tab, Typography} from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import Card from '@material-ui/core/Card';
import RegisterForm from '../register/register.component';
import Link from '@material-ui/core/Link';
import translation from "../../../translation/translate";
import style from '../../../assets/css/style'
import {toast} from "react-toastify";

const color = require(`../../../assets/css/${process.env.REACT_APP_CLIENT}/style.jsx`);

const firstColor = color.firstColor;

const stylesSelected = {
    notSelected: {
        background: firstColor,
        color: '#fff',
    },
    selected: {
        background: '#fff',
        color: firstColor,
    },
    paperWrapper: {
        marginBottom: '40px',
    },
};

function TabContainer(props: any) {
    return (
        <Typography component='div' style={{padding: 24}}>
            {props.children}
        </Typography>
    );
}

TabContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

interface Props {
    login: any;
    history: any;
    classes: any;
}

interface State {
    loading: boolean;
    value: any;
}

class LoginPage extends React.Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: false,
            value: 0
        };
    }

    userLoggedIn = (data: any) => {
        this.setState({loading: true});
        this.props.login(data)
            .then(() => {
                this.props.history.push('/')
            })
            .catch((error: any) => {
                console.error(error)
                toast.error(translation[process.env.REACT_APP_CLIENT as string].error_mdp)
                this.setState({loading: false})
            })
    };

    handleChange = (event: any, value: any) => {
        this.setState({value});
    };

    render() {
        const {classes, history} = this.props;
        const {value} = this.state;
        return <div style={process.env.REACT_APP_TEMPLATE_LOGIN === 'false' ? style.backgroundImage : null as any}>
            <div style={{...style.container, ...style.containerCustom} as any}>
                <Grid container spacing={3} style={{marginTop: '18px'}} justify='center' alignItems="center">
                    <Grid item md={8} lg={4} xs={11} style={{marginTop: '7%'}}>
                        <Paper
                            classes={{
                                root: classes.paperWrapper,
                            }}
                        >
                            <Tabs
                                value={value}
                                onChange={this.handleChange}
                                variant="fullWidth"
                                TabIndicatorProps={{
                                    style: {
                                        backgroundColor: firstColor
                                    }
                                }}
                            >
                                <Tab
                                    classes={{
                                        root: classes.notSelected,
                                        selected: classes.selected,
                                    }}
                                    label='CONNEXION'
                                />

                                <Tab
                                    classes={{
                                        root: classes.notSelected,
                                        selected: classes.selected,
                                    }}
                                    label='INSCRIPTION'
                                />
                            </Tabs>
                            {value === 0 && <TabContainer><LoginForm login={this.userLoggedIn} classes={classes}/></TabContainer>}
                            {value === 1 && <TabContainer><RegisterForm classes={classes} history={history}/></TabContainer>}
                        </Paper>
                    </Grid>
                    {process.env.REACT_APP_TEMPLATE_LOGIN === 'true' &&
                    <Grid item md={4} lg={3} xs={10}>
                        <Card style={{padding: '20px'}}>
                            <Typography variant="h5" component="h2">
                                Besoin d'aide ?
                            </Typography>
                            <Typography color="textSecondary" gutterBottom>
                                Contactez le service consommateur SOGEC
                                <br/><br/>
                                Par téléphone :
                            </Typography>
                            <Typography variant="h5" component="p">
                                01 69 79 25 54
                            </Typography>
                            <p>Du lundi au vendredi de 9h à 18h</p>
                            <Typography color="textSecondary" gutterBottom>
                                <br/>
                                Par e-mail :
                                <br/>
                                <Link href="" style={style.email_aide}>
                                    {translation[process.env.REACT_APP_CLIENT as string].email_aide}
                                </Link>
                            </Typography>
                        </Card>
                    </Grid>
                    }
                </Grid>
            </div>
        </div>;
    }
}

export default connect(null, {login})(withStyles({...stylesSelected as any})(LoginPage));
