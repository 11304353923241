import api from '../../api/index'
import history from '../../history'
import authApi from './auth.action'
export const USER_LOGGED_SUCCESS = 'USER_LOGGED_SUCCESS';
export const USER_LOGGED_ERROR = 'USER_LOGGED_ERROR';
export const USER_LOGGED_OUT = 'USER_LOGGED_OUT';

export function loginSuccess(token) {
  return {
    type: USER_LOGGED_SUCCESS,
    token
  }
}

export function loginError(error) {
  return {
    type: USER_LOGGED_ERROR,
    error
  }
}

export function userLoggedOut() {
  return {
    type: USER_LOGGED_OUT
  }
}

export const logout = () => (dispatch) => {
  sessionStorage.clear();
  api.setAuthorizationHeader();
  dispatch(userLoggedOut());
  history.push('/login')
};

export const login = (credential)  => (dispatch) => {
  return (
      authApi.login(credential)
    .then(res => {
      const token = res.data.token;
      sessionStorage.user_jwt = token;
      api.setAuthorizationHeader(token);
      dispatch(loginSuccess(token))
    })
    .catch(error => {
      api.setAuthorizationHeader();
      dispatch(loginError(error.response.data));

      throw error
    })
)};
