import React, {Component} from 'react';
import {FieldControl, FormControl} from "react-reactive-form";
import TextField from '@material-ui/core/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Grid from '@material-ui/core/Grid';
import {errorFormMessage} from '../utils/error-form-message'
import style from "../../assets/css/style";

interface Props {
    inputName: any;
    type?: 'number' | 'string' | 'password' | 'text' | 'email';
    adornment?: any;
    inputIcon?: any;
    variant?: string;
    inputLabel: string;
    defaultValue?: string;
    validators?: string;
    styleUniqueOne?: any;
    styleUniqueTwo?: any;
    fieldControlMeta?: any;
    rows?: any;
    customRenderValue?(value: string): string;
    customChangeValue?(value: any): any;
}

class ReactiveFormInputText extends Component <Props> {

    renderInput(formControl: FormControl) {
        const handler = formControl.handler();
        const optionalProps: any = {};
        let errorMessage = null;
        if ((formControl.touched || formControl.submitted) && !!formControl.errors) {
            errorMessage = errorFormMessage(formControl.errors, this.props.inputName, this.props.type);
            optionalProps.helperText = errorMessage;
        }
        if (this.props.type) {
            optionalProps.type = this.props.type;
        }
        if (this.props.adornment) {
            optionalProps.InputProps = {
                endAdornment: (<InputAdornment position={'end'}>{this.props.adornment.label}</InputAdornment>)
            };
        }
        if (this.props.rows) {
            optionalProps.rows = this.props.rows;
            optionalProps.multiline = true;
        }

        return (
            <Grid container alignItems="flex-end">
                <Grid item xs={1} style={style.iconProfil}>
                    {this.props.inputIcon}
                </Grid>
                <Grid item xs={10}
                      style={{marginLeft: this.props.styleUniqueOne, marginRight: this.props.styleUniqueTwo}}>
                    <TextField data-cy={this.props.inputName} style={{width: '100%'}}
                               variant={this.props.variant}
                               type={this.props.type}
                               error={(formControl.touched || formControl.submitted) && !!formControl.errors}
                               label={this.props.inputLabel}
                               {...handler}
                               {...optionalProps}
                    />
                </Grid>
            </Grid>
        )
    }

    render() {
        const optionalProps: any = {};
        if (this.props.fieldControlMeta) {
            optionalProps.meta = this.props.fieldControlMeta;
        }
        if (this.props.validators) {
            optionalProps.options = {validators: this.props.validators}
        }
        if (this.props.defaultValue) {
            optionalProps.formState = this.props.defaultValue;
        }
        return (
            <FieldControl
                name={this.props.inputName}
                render={this.renderInput.bind(this)}
                {...optionalProps}
            />
        )
    }
}

export default ReactiveFormInputText;

