import auth from '../authentication/actions/auth.action'
import user from '../profil/actions/user.action'
import operations from '../operations/actions/operations.action'
import media_object from "../participations/actions/mediaObject"
import participation from "../participations/actions/participation.action"
import setAuthorizationHeader from "../authentication/actions/setAuthorizationHeader.actions"
const exportedObject = {
    auth,
    user,
    operations,
    media_object,
    participation,
    setAuthorizationHeader
};

export default exportedObject;

