import React from 'react'
import Button from '@material-ui/core/Button';
import { MyLocation, Map, Place, Phone, Email, LockOpen } from '@material-ui/icons'
import api from '../../api'
import ReactiveFormInputText from "../../shared/form/reactive-form-input-text.component";
import {FieldGroup, FormBuilder, Validators} from "react-reactive-form";
import {toast } from 'react-toastify';
import style from '../../assets/css/style';
import Grid from "@material-ui/core/Grid";
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import translation from "../../translation/translate";
import {PROFILE_FORM} from '../../shared/utils/variable'

interface Props {
    classes:any;
    data:any
}

class ProfileForm extends React.Component <Props>{
    profileForm = FormBuilder.group({
        [PROFILE_FORM.ADDRESS]: [""],
        [PROFILE_FORM.POSTAL_CODE]: [""],
        [PROFILE_FORM.CITY]: [""],
        [PROFILE_FORM.PHONE]: [""],
        [PROFILE_FORM.EMAIL]: [""],
        [PROFILE_FORM.CURRENT_PWD]: ["",Validators.required],
    });

  handleSubmit = (e: { preventDefault: () => void; }) => {
    e.preventDefault();
      api.user.update(this.profileForm.value)
        .then(() => {
            toast.success('Votre profil a été mis à jour');
        })
        .catch(error => {
            toast.error('Mot de passe incorrect');
            console.error(error)
        })
  };

  render() {
    const { classes } = this.props;
    this.profileForm.controls[PROFILE_FORM.ADDRESS].setValue(this.props.data[PROFILE_FORM.ADDRESS]);
    this.profileForm.controls[PROFILE_FORM.POSTAL_CODE].setValue(this.props.data[PROFILE_FORM.POSTAL_CODE]);
    this.profileForm.controls[PROFILE_FORM.CITY].setValue(this.props.data[PROFILE_FORM.CITY]);
    this.profileForm.controls[PROFILE_FORM.PHONE].setValue(this.props.data[PROFILE_FORM.PHONE]);
    this.profileForm.controls[PROFILE_FORM.EMAIL].setValue(this.props.data[PROFILE_FORM.EMAIL]);

    return (
        <FieldGroup
            control={this.profileForm}
            render={() => (
                <form onSubmit={this.handleSubmit}>
                    <Grid container>
                        <Grid item xs={12}>
                            <ReactiveFormInputText inputLabel={'Adresse'} inputName={PROFILE_FORM.ADDRESS} inputIcon={<Map/>} type={'text'}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ReactiveFormInputText inputLabel={'Code postal'} inputName={PROFILE_FORM.POSTAL_CODE} inputIcon={<MyLocation/>} type={'text'}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ReactiveFormInputText inputLabel={'Ville'} inputName={PROFILE_FORM.CITY} inputIcon={<Place/>} type={'text'}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ReactiveFormInputText inputLabel={'Téléphone'} inputName={PROFILE_FORM.PHONE} inputIcon={<Phone/>} type={'text'}/>
                        </Grid>
                        <Grid item xs={6} >
                            <ReactiveFormInputText styleUniqueOne={'8%'} inputLabel={translation[process.env.REACT_APP_CLIENT as string].contact_label} inputName="lastName" inputIcon={<AccountBoxIcon/>} type={'text'}/>
                        </Grid>
                       <Grid item xs={6} >
                            <ReactiveFormInputText styleUniqueTwo={'10%'} inputLabel={' '} inputName="firstName" type={'text'}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ReactiveFormInputText inputLabel={'Email'} inputName={PROFILE_FORM.EMAIL} inputIcon={<Email/>} type={'text'}/>
                        </Grid>
                        <Grid item xs={12}>
                            <ReactiveFormInputText inputLabel={'Mot de passe *'} inputName={PROFILE_FORM.CURRENT_PWD}inputIcon={<LockOpen/>} type={'password'}/>
                        </Grid>
                    </Grid>
                <div className={classes.center}>
                  <Button
                      style = {style.btnLogin}
                    type='submit'>
                    Enregistrer
                  </Button>
                </div>
            </form>
            )}
        />
    );
  }
}

export default ProfileForm
