import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import axios from 'axios'
import { composeWithDevTools } from 'redux-devtools-extension'
import auth from './authentication/reducers/index.reducers'
import api from './api/index'

import { loginSuccess, logout } from './authentication/actions/auth-function.action'

const Todo = () => {
  let store = createStore(
      auth,
    composeWithDevTools(applyMiddleware(thunk))
  )
  axios.defaults.baseURL = process.env.REACT_APP_API_URL
  axios.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    const originalRequest = error.config;
    if (error.response.status === 401) {
      originalRequest._retry = true;
      if (sessionStorage.user_jwt) {
        sessionStorage.clear()
        store.dispatch(logout())
      }
    }

    return Promise.reject(error);
  })

  if (sessionStorage.user_jwt) {
    api.setAuthorizationHeader(sessionStorage.user_jwt)
    store.dispatch(loginSuccess(sessionStorage.user_jwt))
  }

  return { store }
}

export default Todo;

