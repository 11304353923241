import React from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ConsumerRequestsTable from './list-particpation.component';
import translation from '../../translation/translate';
import style from '../../assets/css/style';

interface Props {
    classes:any;
}

class ConsumerRequestPage extends React.Component <Props> {

  render() {
    const { classes } = this.props;
    return (
        <Grid container justifyContent='center' style={{position: 'relative', minHeight: 'calc(100vh - 82px)', paddingTop: '85px'}}>
            <Grid item xs={12} sm={12} md={6}>
                <h3 style={style.titleBis as any}>{translation[process.env.REACT_APP_CLIENT as string].list_title}</h3>
            </Grid>
            <Grid item xs={10}>
              <Card>
                <CardContent >
                  <ConsumerRequestsTable classes={classes} />
                </CardContent>
              </Card>
            </Grid>
          </Grid>
    );
  }
}

export default withStyles({...style as any})(ConsumerRequestPage)
