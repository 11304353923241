import gql from 'graphql-tag'

gql`
fragment opeFields on operation {
  title
  description
  needIban
  products {
    edges {
      node {
        id
        ean
        label
      }
    }
  }
}
`
const GET_OPE_FOR_NEW_PART = gql`
  query getOpeForNewPart ($ope_id: ID! $counter_id: ID!, $user_id: ID!) {
    operation (id: $ope_id) {
      title
      description
      needIban
      image {
        contentUrl
      }
      contract {
        contentUrl
      }
    }
    counter: customerOperation(id: $counter_id) {
      partMax
      counter
    }
    user: customer(id: $user_id) {
      cachedIban
    }
  }
`

const GET_OPE_FOR_EDIT_PART = gql`
  query getOpeForEditPart ($ope_id: ID! $counter_id: ID!, $user_id: ID!, $part_id: ID!) {
    operation (id: $ope_id) {
      title
      description
      needIban
      image {
        contentUrl
      }
      contract {
        contentUrl
      }
      products {
        edges {
          node {
            id
            ean
            label
          }
        }
      }
    }
    counter: customerOperation(id: $counter_id) {
      partMax
      counter
    }
 
    user: customer(id: $user_id) {
      cachedIban
    }
    old_part: participation(id: $part_id) {
      id
      code
      state {
        id
      }
    }
  }
`

export {
  GET_OPE_FOR_NEW_PART,
  GET_OPE_FOR_EDIT_PART
}
