// ##############################
// // // PricingPage Pages View styles
// #############################

import {
  container,
} from "./material-dashboard-pro-react.jsx";

const pricingPageStyle = {
  container: {
    ...container
  },
};

export default pricingPageStyle;
