import React from 'react'
import ResetPwdForm from './reset-password-form.component';
import style from '../../../assets/css/style'

interface Props {
  classes:any,
  match: any,
  history: any,
}

class ResetPwdPage extends React.Component<Props> {
  render() {
    const { classes, match, history } = this.props;

    return (
      <div style={style.containerCustom as any}>
        <ResetPwdForm classes={classes} token={match.params.token} history={history}/>
      </div>
    );
  }
}

export default ResetPwdPage
