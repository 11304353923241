import React, {useCallback} from 'react';
import {useHistory} from "react-router";
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Button from '@material-ui/core/Button'

export default function HeaderLink({path, classes, label}) {
    const history = useHistory();

    const handleClick = useCallback((e) => {
        e.preventDefault();
        history.push(path);
    }, [path]);

    return (
        <ListItem className={classes.listItem}>
            <Button onClick={handleClick}>
                <ListItemText
                    primary={label}
                    disableTypography={true}
                    className={classes.listItemText}
                />
            </Button>
        </ListItem>
    );
}
