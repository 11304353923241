import axios from 'axios';

const authApi = {
  login: (credentials: any) => (
    axios.post(`${process.env.REACT_APP_API_URL}-client/login_check`, credentials)
  ),
  register: (data: any) => (
    axios.post(`customers/register`, data)
  ),
  forgot_pwd: (email: string) => (
    axios.post(`customers/forgot-password-request`, email)
  ),
  forgot_pwd_token: (token: string) => (
    axios.post(`customers/forgot-password-token`, { token: token })
  ),
  reset_password: (data: any) => (
    axios.post(`customers/forgot-password-reset`, data)
  )
};

export default authApi;
