import axios from 'axios';
import qs from 'qs';

const operationApi = {
  my_operations: () => (
    axios.get(`operations/active`)
  ),
  operation_label: () => (
      axios.get(`operation_label?pageSize=1000`)
  ),
  my_participations: (params: any) => (
    axios.get(`participations`, {
      params: params,
      paramsSerializer: function(params) {
        return qs.stringify(params, { encode: false });
      }
    })
  ),
};

export default operationApi
