import React from "react";
import { connect } from "react-redux";
import { Route, Redirect } from "react-router-dom";

interface Props {
  isAuthenticated:any;
  component:any;
}

class AuthRoute extends React.PureComponent <Props>{
  render() {
    const { component: Component, ...rest} = this.props;
    return (<Route
    {...rest}
    render={props =>
      this.props.isAuthenticated ? <Component {...props} /> : <Redirect to="/login" />}
  />)
  }
}

function mapStateToProps(state: { auth: { token: any; }; }) {
  return {
    isAuthenticated: !!state.auth.token
  };
}

export default connect(mapStateToProps)(AuthRoute);
