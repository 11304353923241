export const translate: { [key: string]: { [key: string]: string } } = {
    henkel: {
        'register_title': 'Formulaire d\'inscription',
        'register_validator_password': '^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)(?=.*[-+.!*$@%_])([-=.*?+@%!&:;,#_\\w]{10,50})$',
        'register_wording_password': 'Minimum 10 caractères et au moins 1 majuscule, 1 chiffre et 1 caractère spécial',
        'login_title': 'Plateforme Points de vente',
        'email_aide': 'henkel@sogec-marketing.fr',
        'home_new': 'Enregistrer les sorties caisses',
        'home_title_new': 'Enregistrer les sorties caisses',
        'home_add_file': 'Ajouter un autre fichier',
        'navbar_demandes': 'Opérations',
        'list_title': 'Suivi de mes opérations',
        'operation_condition': 'Conditions générales de participation',
        'operation_condition_challenge': 'Téléchargez le règlement du challenge',
        'label_cip_register': 'Code Interne',
        'error_mdp': 'Nom d\'utilisateur ou mot de passe incorrecte.',
        'contact_label': 'Contact chef de rayon'
    },
    roge_cavailles: {
        'register_title': 'Formulaire d\'inscription',
        'register_validator_password': '^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)(?=.*[-+.!*$@%_])([-=.*?+@%!&:;,#_\\w]{10,50})$',
        'register_wording_password': 'Minimum 10 caractères et au moins 1 majuscule, 1 chiffre et 1 caractère spécial',
        'login_title': 'Plateforme partenaires',
        'email_aide': 'rogecavailles@sogec-marketing.fr',
        'home_new': 'Je participe',
        'home_title_new': 'Charger mes pièces justificatives',
        'home_add_file': 'Ajouter une autre demande',
        'navbar_demandes': 'Mes participations',
        'list_title': 'Suivi de mes participations',
        'operation_condition': 'Téléchargez votre contrat de mandat',
        'operation_condition_challenge': 'Téléchargez le règlement du challenge',
        'label_cip_register': 'Code CIP Pharmacie ',
        'error_mdp': 'Nom d\'utilisateur ou mot de passe incorrecte.',
        'contact_label': 'Contact Pharmacie'
    },
    nestle: {
        'register_title': 'Formulaire d\'inscription',
        'register_validator_password': '^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)(?=.*[-+.!*$@%_])([-=.*?+@%!&:;,#_\\w]{16,50})$',
        'register_wording_password': 'Minimum 16 caractères et au moins 1 majuscule, 1 chiffre et 1 caractère spécial',
        'login_title': 'Plateforme partenaires',
        'email_aide': 'rogecavailles@sogec-marketing.fr',
        'home_new': 'Je participe',
        'home_title_new': 'Charger mes pièces justificatives',
        'home_add_file': 'Ajouter une autre demande',
        'navbar_demandes': 'Mes participations',
        'list_title': 'Suivi de mes participations',
        'operation_condition': 'Mandat',
        'operation_condition_challenge': 'Mandat',
        'label_cip_register': 'Code CIP Pharmacie ',
        'error_mdp': 'Nom d\'utilisateur ou mot de passe incorrecte.',
        'contact_label': 'Contact Pharmacie'
    },
    abinbev: {
        'register_title': 'Formulaire d\'inscription',
        'register_validator_password': '^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)(?=.*[-+.!*$@%_])([-=.*?+@%!&:;,#_\\w]{10,50})$',
        'register_wording_password': 'Minimum 10 caractères et au moins 1 majuscule, 1 chiffre et 1 caractère spécial',
        'login_title': 'Plateforme partenaires',
        'email_aide': 'abinbev@sogec-marketing.fr',
        'home_new': 'Je participe',
        'home_title_new': 'J\'enregistre mes téléchargements',
        'home_add_file': 'Ajouter une autre demande',
        'navbar_demandes': 'Mes téléchargements',
        'list_title': 'Suivi de mes téléchargements',
        'operation_condition': 'Conditions générales de participation',
        'operation_condition_challenge': 'Téléchargez le règlement du challenge',
        'label_cip_register': 'Code CIP Pharmacie ',
        'error_mdp': 'Nom d\'utilisateur ou mot de passe incorrecte.',
        'contact_label': 'Contact Pharmacie'
    },
    melisana: {
        'register_title': 'Formulaire d\'inscription',
        'register_validator_password': '^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)(?=.*[-+.!*$@%_])([-=.*?+@%!&:;,#_\\w]{10,50})$',
        'register_wording_password': 'Minimum 10 caractères et au moins 1 majuscule, 1 chiffre et 1 caractère spécial',
        'login_title': 'Plateforme partenaires',
        'email_aide': 'melisana@sogec-marketing.fr',
        'home_new': 'Je participe',
        'home_title_new': 'J\'enregistre mes téléchargements',
        'home_add_file': 'Ajouter une autre demande',
        'navbar_demandes': 'Mes téléchargements',
        'list_title': 'Suivi de mes téléchargements',
        'operation_condition': 'Conditions générales de participation',
        'operation_condition_challenge': 'Téléchargez le règlement du challenge',
        'label_cip_register': 'Code CIP Pharmacie ',
        'error_mdp': 'Nom d\'utilisateur ou mot de passe incorrecte.',
        'contact_label': 'Contact Pharmacie'
    },
    colgate: {
        'register_title': 'Formulaire d\'inscription',
        'register_validator_password': '^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)(?=.*[-+.!*$@%_])([-=.*?+@%!&:;,#_\\w]{10,50})$',
        'register_wording_password': 'Minimum 10 caractères et au moins 1 majuscule, 1 chiffre et 1 caractère spécial',
        'login_title': 'Plateforme partenaires',
        'email_aide': 'melisana@sogec-marketing.fr',
        'home_new': 'Je participe',
        'home_title_new': 'J\'enregistre mes téléchargements',
        'home_add_file': 'Ajouter une autre demande',
        'navbar_demandes': 'Mes téléchargements',
        'list_title': 'Suivi de mes téléchargements',
        'operation_condition': 'Conditions générales de participation',
        'operation_condition_challenge': 'Téléchargez le règlement du challenge',
        'label_cip_register': 'Code CIP Pharmacie ',
        'error_mdp': 'Nom d\'utilisateur ou mot de passe incorrecte.',
        'contact_label': 'Contact Pharmacie'
    },
    unilever: {
        'register_title': 'Formulaire d\'inscription',
        'register_validator_password': '^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)(?=.*[-+.!*$@%_])([-=.*?+@%!&:;,#_\\w]{10,50})$',
        'register_wording_password': 'Minimum 10 caractères et au moins 1 majuscule, 1 chiffre et 1 caractère spécial',
        'login_title': 'Plateforme Points de vente',
        'email_aide': 'service-consommateur@sogec-marketing.fr',
        'home_new': 'Je participe',
        'home_title_new': 'Je participe',
        'home_add_file': 'Ajouter un autre fichier',
        'navbar_demandes': 'Opérations',
        'list_title': 'Suivi de mes opérations',
        'operation_condition': 'Téléchargez votre contrat de mandat',
        'operation_condition_challenge': 'Téléchargez le règlement du challenge',
        'label_cip_register': 'Numéro de client ',
        'error_mdp': 'Nom d\'utilisateur ou mot de passe incorrecte.',
        'contact_label': 'Contact point de vente'
    },
    sogec: {
        'register_title': 'Formulaire d\'inscription',
        'register_validator_password': '^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)(?=.*[-+.!*$@%_])([-=.*?+@%!&:;,#_\\w]{10,50})$',
        'register_wording_password': 'Minimum 10 caractères et au moins 1 majuscule, 1 chiffre et 1 caractère spécial',
        'login_title': 'Plateforme Points de vente',
        'email_aide': 'sogec@sogec-marketing.fr',
        'home_new': 'Je participe',
        'home_title_new': 'Je participe',
        'home_add_file': 'Ajouter un autre fichier',
        'navbar_demandes': 'Opérations',
        'list_title': 'Suivi de mes opérations',
        'operation_condition': 'Téléchargez votre contrat de mandat',
        'operation_condition_challenge': 'Téléchargez le règlement du challenge',
        'label_cip_register': 'Code CIP Pharmacie ',
        'error_mdp': 'Nom d\'utilisateur ou mot de passe incorrecte.',
        'contact_label': 'Contact Pharmacie'
    },
    pepsico: {
        'register_title': 'Formulaire d\'inscription',
        'register_validator_password': '^(?=.*[A-Z])(?=.*[a-z])(?=.*\\d)(?=.*[-+.!*$@%_])([-=.*?+@%!&:;,#_\\w]{10,50})$',
        'register_wording_password': 'Minimum 10 caractères et au moins 1 majuscule, 1 chiffre et 1 caractère spécial',
        'login_title': 'Plateforme Points de vente',
        'email_aide': 'service-consommateur@sogec-marketing.fr',
        'home_new': 'Je participe',
        'home_title_new': 'Je participe',
        'home_add_file': 'Ajouter un autre fichier',
        'navbar_demandes': 'Opérations',
        'list_title': 'Suivi de mes opérations',
        'operation_condition': 'Téléchargez votre contrat de mandat',
        'operation_condition_challenge': 'Téléchargez le règlement du challenge',
        'label_cip_register': 'Code Interne ',
        'error_mdp': 'Nom d\'utilisateur ou mot de passe incorrecte.',
        'contact_label': 'Contact Magasin'
    },
}

export default translate;
