import axios from 'axios';

const mediaObjectApi = {
  uploadFile: (params: any) => (
    axios.post(`media_objects`, params)
  ) ,
  update: (id: string, params: any) => (
    axios.post(id, params)
  ),
};

export default mediaObjectApi
