import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import ProfileForm from './personal-form.component'
import ChangePwdForm from './password-form.component'
import IbanBicForm from './iban-bic-form.component'
import api from '../../api'
import pricingPageStyle from '../../assets/css/pricingPageStyle.jsx'
import style from '../../assets/css/style';

interface Props {
  classes:any;
}

interface State {
  user:any;
}

class ProfilePage extends React.Component<Props, State> {
  state = {
    user: "",
  };

  componentDidMount() {
    api.user.get_curr()
      .then(resp => {
        this.setState({ user: resp.data })
      })
  }

  render() {
    const { classes } = this.props;
    return (
          <Grid container justifyContent='center' style={{position: 'relative', minHeight: 'calc(100vh - 82px)', paddingTop: '85px'}}>
              <Grid item xs={12} sm={12} md={12}>
                <h3 style={style.title as any}>Mon profil</h3>
              </Grid>
            <Grid item xs={10} sm={10} md={8}>
              <Card className={classes.cardSignup}>
                <CardContent>
                  <Grid container justifyContent='center'>
                    <Grid item sm={6} md={6}>
                      <h4 style={style.subTitleProfil as any}>Modifier mon profil</h4>
                      <ProfileForm data={this.state.user} classes={classes} />
                    </Grid>
                    <Grid item sm={6} md={6}>
                      <h4 style={style.subTitleProfil as any}>Modifier mes coordonnées bancaires</h4>
                      <IbanBicForm data={this.state.user} classes={classes}/>
                      <h4 style={style.subTitleProfil as any}>Modifier mon mot de passe</h4>
                      <ChangePwdForm classes={classes} />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
    );
  }
}

export default withStyles(pricingPageStyle as any)(ProfilePage)
