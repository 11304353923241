import { USER_LOGGED_OUT, USER_LOGGED_SUCCESS } from "../actions/auth-function.action";
import { USER_LOGGED_ERROR } from "../actions/auth-function.action";

interface Action {
  type: any;
  token:string;
  error:any;
}

export default function auth(state={}, action:Action) {
  switch (action.type) {
    case USER_LOGGED_SUCCESS:
      return { error: null, token: action.token };
    case USER_LOGGED_ERROR:
      return { error: action.error, token: null };
    case USER_LOGGED_OUT:
      return {};
    default:
      return state;
  }
}
