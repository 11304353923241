import React from 'react'
import Email from '@material-ui/icons/Email'
import Close from '@material-ui/icons/Close'
import Slide from '@material-ui/core/Slide'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import Button from '@material-ui/core/Button';
import {FieldGroup, FormBuilder, Validators} from "react-reactive-form";
import {connect} from 'react-redux'
import notificationsStyle from '../../../assets/css/notificationsStyle.jsx'
import {withStyles} from '@material-ui/core'
import ReCAPTCHA from "react-google-recaptcha";
import Grid from '@material-ui/core/Grid';
import style from '../../../assets/css/style';
import {toast, ToastContainer} from "react-toastify";
import authApi from '../../actions/auth.action'
import {PROFILE_FORM} from "../../../shared/utils/variable";
import ReactiveFormInputText from "../../../shared/form/reactive-form-input-text.component";

interface Props {
    classes: any;
}

interface State {
    email: any;
    open_request_pwd: boolean;
    errors: object;
    loading: boolean;
    isVerified: boolean;
    data: object;
}

function Transition(props: any) {
    return <Slide direction='down' {...props} />
}

class RequestPwdForm extends React.Component<Props, State> {
    private dataForm: null;
    private readonly recaptchaRef: React.RefObject<unknown>;

    constructor(props: Props) {
        super(props);
        this.verifyCallback = this.verifyCallback.bind(this);
        this.state = {
            email: '',
            open_request_pwd: false,
            errors: {},
            loading: false,
            isVerified: false,
            data: {}
        };
        this.recaptchaRef = React.createRef();
        this.dataForm = null;
    }

    emailForm = FormBuilder.group({
        [PROFILE_FORM.EMAIL]: ["", Validators.email],
    });

    verifyCallback(response: any) {
        if (response) {
            this.setState({isVerified: true});
            this.dataForm = null;
        }
    }

    handleForgotPwd = (e: { preventDefault: () => void }) => {
        e.preventDefault();
        // @ts-ignore
        //this.recaptchaRef.current.execute();
        authApi.forgot_pwd({email: this.emailForm.value.email} as any)
            .then(() => {
                this.handleClose();
                toast.success('Une url vous a été envoyé à votre adresse email afin de réinitialiser votre mot de passe.')
                this.setState({loading: false})
            })
            .catch(() => {
                toast.error('Aucun utilisteur ne correspond à cet adresse email.')
                this.setState({loading: false})
            })
    };

    onChange = (e: { target: { value: any } }) => {
        this.setState({email: e.target.value})
    };

    handleOpen = () => {
        this.setState({open_request_pwd: true})
    };

    handleClose = () => {
        this.setState({open_request_pwd: false})
    };

    render() {
        const {classes} = this.props
        const {open_request_pwd} = this.state

        return (
            <React.Fragment>
                <ToastContainer/>
                <Button onClick={this.handleOpen} style={style.btnPswForget}>Mot de passe oublié</Button>
                <Dialog
                    classes={{
                        root: classes.center + ' ' + classes.modalRoot,
                        paper: classes.modal
                    }}
                    open={open_request_pwd}
                    TransitionComponent={Transition}
                    keepMounted
                    onClose={() => this.handleClose()}
                    aria-labelledby='forgot-pwd-modal-slide-title'
                    aria-describedby='forgot-pwd-modal-slide-description'
                >
                    <DialogTitle
                        id='forgot-pwd-modal-slide-title'
                        disableTypography
                        className={classes.modalHeader}
                    >
                        <Button
                            className={classes.modalCloseButton}
                            key='close'
                            aria-label='Close'
                            onClick={() => this.handleClose()}
                        >
                            <Close className={classes.modalClose}/>
                        </Button>
                        <h4
                            className={classes.root}
                            style={style.modalTitle as any}
                        >
                            Mot de passe oublié
                        </h4>
                    </DialogTitle>
                    <FieldGroup
                        control={this.emailForm}
                        render={() => (
                            <form onSubmit={this.handleForgotPwd}>
                                <DialogContent
                                    id='forgot-pwd-modal-slide-description'
                                    className={classes.modalBody}
                                >
                                    <ReactiveFormInputText inputLabel={'Email'} inputName={PROFILE_FORM.EMAIL}
                                                           inputIcon={<Email/>} type={'text'}/>
                                </DialogContent>
                                <DialogActions className={classes.modalFooter}>
                                    <Grid container justifyContent='center'>
                                        <Grid item sm={6} md={6}>
                                            <Button
                                                onClick={() => this.handleClose()}
                                                className={classes.root}
                                                style={style.btnCancel}
                                            >
                                                Annuler
                                            </Button>
                                        </Grid>
                                        <Grid item sm={6} md={6}>
                                            <Button
                                                type='submit'
                                                className={classes.root}
                                                style={style.btnSend}
                                            >
                                                Envoyer
                                            </Button>
                                        </Grid>
                                        <Grid item sm={12} md={6}>
                                            <ReCAPTCHA
                                                ref={this.recaptchaRef as any}
                                                sitekey="6LfdZcwZAAAAAK1NIvgNTS5T8b8JW3ef3wf-AHsy"
                                                size="invisible"
                                                onChange={this.verifyCallback}
                                                theme="dark"
                                            />
                                        </Grid>
                                    </Grid>
                                </DialogActions>
                            </form>
                        )}
                    />
                </Dialog>
            </React.Fragment>
        )
    }
}

export default connect(null)(withStyles(notificationsStyle as any)(RequestPwdForm))
