import React, {Fragment} from 'react';
import withStyles from '@material-ui/core/styles/withStyles';
import Grid from '@material-ui/core/Grid';
import CircularProgress from '@material-ui/core/CircularProgress';
import RequestForm from './create-participation-form.component';
import {Query} from 'react-apollo';
import {GET_OPE_FOR_EDIT_PART, GET_OPE_FOR_NEW_PART} from '../actions/query';
import {getUserToken} from '../../shared/utils/utils';
import translation from '../../translation/translate';
import style from '../../assets/css/style';

interface Props {
    classes:any;
    match:{params:any};
    history:any;
}

class ConsumerNewRequestPage extends React.Component<Props> {
    render() {
        const {classes, match: {params}, history} = this.props;
        const token = getUserToken() as any;
        const user_id = token.id;
        const graph_query = params.part_id !== undefined ? GET_OPE_FOR_EDIT_PART : GET_OPE_FOR_NEW_PART;
        return (
            <div className={classes.content}>
                <div className={classes.container}>
                    <Query
                        query={graph_query}
                        variables={{
                            ope_id: `/api/operations/${params.ope_id}/active`,
                            counter_id: `/api/customer_operations/customer=${user_id};operation=${params.ope_id}`,
                            user_id: `/api/${token.type}/${user_id}`,
                            part_id: `/api/participations/${params.part_id}`,
                            is_groups: token.type === 'groupements'
                        }}
                        fetchPolicy="network-only"
                    >
                        {({data, error, loading}:any) => {
                            if (loading) {
                                return <Fragment>
                                    <Grid container justifyContent='center'>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <Grid container justifyContent='center'><CircularProgress
                                                style={{marginTop: '12%', position: 'absolute'}}
                                                color="primary"/></Grid>
                                        </Grid>
                                    </Grid>
                                </Fragment>
                            }
                            if (error) return <Grid container justifyContent='center'><p
                                style={{marginTop: '12%', position: 'absolute'}}>Problème, veuillez réessayer
                                ulterieurement.</p></Grid>
                            const has_limit = data.counter !== null && data.counter.partMax !== null;
                            const part_limit = has_limit ? data.counter.partMax - data.counter.counter : null;

                            return <Fragment>
                                <Grid container justifyContent='center'>
                                    <Grid item xs={12} sm={12} md={12}>
                                        <h3 style={style.title as any }>{data.old_part === undefined ? translation[process.env.REACT_APP_CLIENT as string].home_title_new : `Modification demande N° ${data.old_part.code}`}</h3>
                                        {data.old_part === undefined && part_limit !== null && part_limit > 0 &&
                                        <h3 className={classes.limitTitle}>Nombre de participations autorisées
                                            : <strong>{part_limit}</strong></h3>}
                                        {data.old_part === undefined && part_limit !== null && part_limit <= 0 &&
                                        <h3 className={classes.limitTitle}>Vous avez atteint le maximum de
                                            participation</h3>}
                                    </Grid>
                                </Grid>
                                {(part_limit === null || part_limit > 0 || data.old_part) &&
                                <RequestForm match_params={params} history={history} data={data} comments={''}/>}
                            </Fragment>
                        }}
                    </Query>
                </div>
            </div>
        );
    }
}


export default withStyles(style as any)(ConsumerNewRequestPage);
