import jwt_decode from 'jwt-decode'

export const toLocaleDateString = (date: string | number | Date) => {
  const new_date = new Date(date);
  return new_date.toLocaleDateString()
};

export const toLocaleString = (date: string | number | Date | null) => {
  if (null === date) {
    return ''
  }
  const new_date = new Date(date);
  return new_date.toLocaleString()
};

export const getUserToken = () => {
  const user_token = sessionStorage.getItem('user_jwt');
  if (null === user_token) {
    return null
  }
  let token = jwt_decode(user_token) as any;
  const types = token.type.split('\\');
  token.type = types[2].toLowerCase() + 's';

  return token
};
