import React from "react";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import style from '../assets/css/style'

interface Props {
  classes: any;
}

class Footer extends React.Component <Props>{

  render () {
    return (
      <footer style={style.footer as any}>
          <div>
            <List style={style.list}>
              <ListItem style={style.inlineBlock}>
                  {process.env.REACT_APP_CLIENT === "roge_cavailles" &&
                  <a href='https://www.rogecavailles.fr/mentions-legales/' rel='noreferrer noopener' target='_blank'>Mentions légales</a>}
                  {process.env.REACT_APP_CLIENT === "henkel" &&
                  <a href='https://www.henkel.fr/conditions-d-utilisation?pageID=422378' rel='noreferrer noopener' target='_blank'>Mentions légales</a>}
              </ListItem>
              <ListItem style={style.inlineBlock}>
                <a href={'/'+ process.env.REACT_APP_CLIENT +'/tutoriel.pdf'} rel='noreferrer noopener' target='_blank' >
                  Tutoriel
                </a>
              </ListItem>
              <ListItem style={{...style.inlineBlock, float: 'right',paddingRight:'12%'}}>
                <a href={"https://sogec-marketing.com/"}>Sogec Digital &copy; 2021</a>
              </ListItem>
            </List>
          </div>
      </footer>
    );
  }
}

export default Footer;
