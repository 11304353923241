export enum PROFILE_FORM {
  IBAN = 'iban',
  BIC = 'bic',
  CURRENT_PWD = 'currentPwd',
  CONFIRM_PWD = 'confirmPassword',
  PLAIN_PWD = 'plainPassword',
  ADDRESS = 'address',
  POSTAL_CODE = 'postalCode',
  CITY = 'city',
  PHONE = 'phone',
  EMAIL = 'email',
}

export enum LOGIN_FORM {
  USERNAME = 'username',
  PASSWORD = 'password',
}

export enum REGISTER_FORM {
  COMPANY_ID = 'companyId',
}

