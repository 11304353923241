import axios from 'axios'

const participationApi = {
  add_new: (params: any) => (
      axios.post(`participations`, params)
  ),
  get: (id:string) => (
      axios.get(`participations/${id}`)
  ),
  get_refund_proof: (id:string) => {
    axios({
      url: `refund-proof/${id}`,
      method: 'GET',
      responseType: 'blob',
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'justificatif_remboursement.pdf');
      document.body.appendChild(link);
      link.click();
    });
  }
};

export default participationApi
